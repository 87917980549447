import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

function initializeSwiperCarousel() {
    const swipers = document.querySelectorAll('.swiper');

    swipers.forEach((swiperContainer) => {
        const swiperControls = swiperContainer.closest('.slider').querySelector('.swiper-controls');

        const nextEl = swiperControls.querySelector('.swiper-button-next-ga');
        const prevEl = swiperControls.querySelector('.swiper-button-prev-ga');
        const paginationEl = swiperControls.querySelector('.swiper-pagination-ga');

        new Swiper(swiperContainer, {
            lazy: true,
            slidesPerView: "auto",
            spaceBetween: 30,
            freeMode: true,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            pagination: {
                el: paginationEl,
                clickable: true,
            },
            breakpoints: {
                320: {
                    spaceBetween: 1,
                },
                768: {
                    spaceBetween: 1,
                }
            }
        });
    });
}

export { initializeSwiperCarousel };
