// Initialiseer Swiper
import { initializeSwiperCarousel } from './swiper-carousel.js';
import 'flowbite';

// Translations object
const translations = {
    nl: {
        name_required: 'Naam is verplicht.',
        company_required: 'Bedrijf is verplicht.',
        email_required: 'E-mail is verplicht.',
        email_invalid: 'Ongeldig e-mailadres.',
        vat_required: 'Ondernemingsnummer is verplicht.',
        vat_invalid: 'Ongeldig ondernemingsnummer.',
        privacy_required: 'U moet akkoord gaan met de privacy policy.',
    },
    fr: {
        name_required: 'Le nom est obligatoire.',
        company_required: 'L\'entreprise est obligatoire.',
        email_required: 'L\'e-mail est obligatoire.',
        email_invalid: 'Adresse e-mail invalide.',
        vat_required: 'Le numéro de TVA est obligatoire.',
        vat_invalid: 'Numéro de TVA invalide.',
        privacy_required: 'Vous devez accepter la politique de confidentialité.',
    }
};

// Determine language from the `lang` variable (from server or page context)
const lang = document.documentElement.lang || 'nl'; // Fallback to 'nl'

// Use translations based on selected language
const i18n = translations[lang] || translations['nl'];

document.addEventListener('DOMContentLoaded', function() {
    const form = document.querySelector('form');
    if (form) {
        form.addEventListener('submit', function(e) {
            // Client-side validation
            let errors = [];

            const name = form.querySelector('input[name="name"]');
            if (name.value.trim() === '') {
                errors.push(i18n.name_required);
            }

            const company = form.querySelector('input[name="company"]');
            if (company.value.trim() === '') {
                errors.push(i18n.company_required);
            }

            const email = form.querySelector('input[name="email"]');
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email.value.trim() === '') {
                errors.push(i18n.email_required);
            } else if (!emailPattern.test(email.value.trim())) {
                errors.push(i18n.email_invalid);
            }

            const vat_number = form.querySelector('input[name="vat_number"]');
            const vatPattern = /^(BE)?0[0-9]{9}$/;
            if (vat_number.value.trim() === '') {
                errors.push(i18n.vat_required);
            } else if (!vatPattern.test(vat_number.value.trim())) {
                errors.push(i18n.vat_invalid);
            }

            const agreePrivacy = form.querySelector('input[name="agree_privacy"]');
            if (!agreePrivacy.checked) {
                errors.push(i18n.privacy_required);
            }

            if (errors.length > 0) {
                e.preventDefault();
                alert(errors.join('\n'));
            }
        });
    }
    initializeSwiperCarousel();
});
